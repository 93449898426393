import { Spin, Typography } from 'antd';
const { Title, Paragraph } = Typography

const styleSpinner = {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}
const LoadingSpinner = () => (
    <div style={styleSpinner}>
        <div>
            <div className='has-text-center'>
                <Spin size="large" />
            </div>
            <Typography className='has-text-center mt-20'>
                <Title level={5}>Mohon tunggu</Title>
                <Paragraph>Sedang memuat data</Paragraph>
            </Typography>
        </div>
    </div>
);

export default LoadingSpinner;