import { Row, Col, Space } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
// import PuprLogo from "../../assets/img/pupr.png";
import ImgIg from "../../assets/icon/instagram-white.png";
import ImgFb from "../../assets/icon/facebook-white.png";
import ImgYt from "../../assets/icon/youtube-white.png";
import ImgTwitter from "../../assets/icon/twitter-white.png";

import {
  FooterSection,
  Title,
  Large,
  Empty,
  AddressText,
  // Image
} from "./styles";
import { useEffect, useState } from "react";
import axios from "axios";

const Footer = ({ t }: any) => {
  const [visitor, setVisitor] = useState({
    pengunjunghariini: 0,
    pengunjungbulanini: 0,
    pengunjungtahunini: 0,
    totalpengunjung: 0,
    pengunjungonline: 0,
  });

  const fetchVisitor = async () => {
    try {
      let response = await axios.get(
        "https://ciptakarya.pu.go.id/admin/Api/Visitor/get"
      );
      if (response.status === 200) {
        if (response.data.status === 200) {
          let { data } = response.data;
          setVisitor({
            pengunjunghariini: data.pengunjunghariini,
            pengunjungbulanini: data.pengunjungbulanini,
            pengunjungtahunini: data.pengunjungtahunini,
            totalpengunjung: data.totalpengunjung,
            pengunjungonline: data.pengunjungonline
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchVisitor();
  }, []);

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={24} xs={24}>
              <Title>Pengunjung</Title>
              <AddressText>Pengunjung Online  : {visitor.pengunjunghariini}</AddressText>
              <AddressText>
                Bulan Ini : {visitor.pengunjungbulanini}
              </AddressText>
              <AddressText>
                Tahun Ini : {visitor.pengunjungtahunini}
              </AddressText>
              <AddressText>Total : {visitor.totalpengunjung}</AddressText>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Title>Laman</Title>
              <Large to="/" left="true">
                Beranda
              </Large>
              <Large left="true" to="/about">
                Profil
              </Large>
              <Large left="true" to="/berita">
                Berita
              </Large>
              <Large left="true" to="/produk">
                Produk Hukum
              </Large>
              <Large left="true" to="/faq">
                FAQ
              </Large>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Empty />
              <Large left="true" to="/galeri-infografis">
                Infografis
              </Large>
              <Large left="true" to="/agenda">
                Agenda
              </Large>
              <Large left="true" to="/galeri">
                Galeri
              </Large>
              <Large left="true" to="/kontak">
                Kontak
              </Large>
              {/* <Large left="true" to="/sitemap">
                Sitemap
              </Large> */}
            </Col>
            <Col lg={6} md={6} sm={24} xs={24}>
              <Row gutter={12} align="middle" className="m-t-1">
                <Col xs={24}>
                  <Title>Alamat</Title>
                  <AddressText>Gedung Cipta Karya Kementerian PUPR</AddressText>
                  <AddressText>
                    Jl.Pattimura no.20 Kebayoran Baru, Jakarta Selatan
                  </AddressText>
                </Col>
                <Col xs={24}>
                  <Space>
                    <a
                      href="https://www.instagram.com/accounts/login/?next=/ditjenciptakarya/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ImgIg} alt="instagram" />
                    </a>
                    <a
                      href="https://id-id.facebook.com/people/Direktorat-Jenderal-Cipta-Karya-PU/100066857310185/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ImgFb} alt="facebook" />
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UClw44wRLIIUq-2KXz9J_Xcg"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ImgYt} alt="youtube" />
                    </a>
                    <a
                      href="https://twitter.com/cipta_karya"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={ImgTwitter} alt="twitter" />
                    </a>
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
