const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/", "/about"],
    exact: true,
    component: "About",
  },
  {
    path: ["/", "/visi-misi"],
    exact: true,
    component: "About/visi",
  },
  {
    path: ["/", "/tugas-fungsi"],
    exact: true,
    component: "About/tugas",
  },
  {
    path: ["/", "/organisasi"],
    exact: true,
    component: "Organisasi",
  },
  {
    path: ["/", "/faq"],
    exact: true,
    component: "Faq",
  },
  {
    path: ["/", "/glosarium"],
    exact: true,
    component: "Glosarium",
  },
  {
    path: ["/", "/sitemap"],
    exact: true,
    component: "Sitemap",
  },
  {
    path: ["/", "/logo"],
    exact: true,
    component: "Logo",
  },
  {
    path: ["/", "/berita"],
    exact: true,
    component: "Berita",
  },
  {
    path: ["/", "/berita-detail"],
    exact: true,
    component: "Berita/detail",
  },
  {
    path: ["/", "/berita-detail-balai"],
    exact: true,
    component: "Berita/detail",
  },
  {
    path: ["/", "/bppw"],
    exact: true,
    component: "Balai",
  },
  {
    path: ["/", "/bppw-detail"],
    exact: true,
    component: "Balai/detail",
  },
  {
    path: ["/", "/search"],
    exact: true,
    component: "Search",
  },
  {
    path: ["/", "/kontak"],
    exact: true,
    component: "Kontak",
  },
  {
    path: ["/", "/produk"],
    exact: true,
    component: "Produk",
  },
  {
    path: ["/produk", "/laporan"],
    exact: false,
    component: "Produk",
  },
  {
    path: ["/produk", "/pedoman"],
    exact: false,
    component: "Produk",
  },
  {
    path: ["/produk", "/profil"],
    exact: false,
    component: "Produk",
  },
  {
    path: ["/produk-buletin"],
    exact: false,
    component: "Buletin",
  },
  {
    path: ["/produk-kegempaan"],
    exact: false,
    component: "Kegempaan",
  },
  {
    path: ["/produk-nspk"],
    exact: false,
    component: "Nspk",
  },
  {
    path: ["/produk-jurnal"],
    exact: false,
    component: "Jurnal",
  },
  {
    path: ["/", "/satupintu"],
    exact: true,
    component: "Satupintu",
  },
  {
    path: ["/", "/satu-data"],
    exact: true,
    component: "Simck/SatuData",
  },
  {
    path: ["/", "/galeri"],
    exact: true,
    component: "Galeri",
  },
  {
    path: ["/", "/galeri-infografis"],
    exact: true,
    component: "Infografis",
  },
  {
    path: ["/", "/lokasi"],
    exact: true,
    component: "Kontak",
  },
  {
    path: ["/", "/agenda"],
    exact: true,
    component: "Agenda",
  },
  {
    path: ["/", "/bppw-profil"],
    exact: true,
    component: "Balai/profil",
  },
  {
    path: ["/", "/profil-pejabat"],
    exact: true,
    component: "Profil/ProfilPejabat",
  },
  {
    path: ["/", "/unit-kerja"],
    exact: true,
    component: "Profil/UnitKerja",
  },
  {
    path: ["/", "/bppw-berita"],
    exact: true,
    component: "Balai/berita",
  },
  {
    path: ["/", "/bppw-produk"],
    exact: true,
    component: "Balai/produk",
  },
  {
    path: ["/", "/bppw-galeri"],
    exact: true,
    component: "Balai/galeri",
  },
  {
    path: ["/", "/direktorat"],
    exact: true,
    component: "Direktorat/profil",
  },
  {
    path: ["/", "/direktorat-berita"],
    exact: true,
    component: "Direktorat/berita",
  },
  {
    path: ["/", "/direktorat-galeri"],
    exact: true,
    component: "Direktorat/galeri",
  },
  {
    path: ["/", "/satudata"],
    exact: true,
    component: "Redirect/satudata",
  },
  {
    path: ["/", "/satupintu/balaitam/index"],
    exact: true,
    component: "Redirect/balaitam",
  },
  {
    path: ["/", "/satupintu/balaits"],
    exact: true,
    component: "Redirect/balaits",
  },
  {
    path: ["/", "/satupintu/balaibsbg"],
    exact: true,
    component: "Redirect/balaibsbg",
  },
  {
    path: ["/", "/satupintu/balaisains"],
    exact: true,
    component: "Redirect/balaisains",
  },
  {
    path: ["/", "/satupintu/balaikpp"],
    exact: true,
    component: "Redirect/balaikpp",
  },
  {
    path: ["/", "/psppop"],
    exact: true,
    component: "Redirect/psppop",
  },
  {
    path: ["/", "/satupintu/home"],
    exact: true,
    component: "Satupintu",
  },
  {
    path: ["/", "/struktur-organisasi"],
    exact: true,
    component: "Organisasi/struktur",
  },
  {
    path: ["/", "/new-page"],
    exact: true,
    component: "NewPage",
  },
  {
    path: ["/", "/bppw-kalimantanutara"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-jakartametro"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-riau"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-kepulauanriau"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-bengkulu"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-bangkabelitung"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-yogyakarta"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sulawesiutara"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-gorontalo"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sulawesibarat"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sulawesitenggara"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-bali"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-nusatenggarabarat"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-maluku"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-malukuutara"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-aceh"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sumaterautara"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sumaterabarat"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-jambi"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sumateraselatan"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-lampung"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-jawabarat"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-banten"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-jawatengah"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-jawatimur"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-kalimantanbarat"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-kalimantantengah"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-kalimantanselatan"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-kalimantantimur"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sulawesitengah"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-sulawesiselatan"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-nusatenggaratimur"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-papua"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["/", "/bppw-papuabarat"],
    exact: true,
    component: "Redirect/balai",
  },
  {
    path: ["*", "*"],
    exact: true,
    component: "NotFound",
  }
];

export default routes;
